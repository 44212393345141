/*
 *   File : external-fund-import.js
 *   Author : https://evoqins.com
 *   Description : External fund import modal
 *   Integrations : NA
 *   Version : 1.0.0
 *   Date : 11-07-2024
*/


import { memo, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

// Components
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { CustomPhoneInput, CustomTextInput, SixDigitOTPInput } from "../Form-Elements";
import { Icon } from "../Icon";
import { APIService } from "../../Service";

//Assets
import ImportPortfolio from "../../Assets/Images/Home/import-portfolio.svg";
import ImportPortfolioSuccess from "../../Assets/Images/Home/import-portfolio-success.webp";
import CloseBtn from "../../Assets/Images/close-btn.svg";
import CloseBtnWhite from "../../Assets/Images/close-btn-white.svg";
import Back from "../../Assets/Images/Login/back-icon.svg";
import Loader from "../../Assets/Images/loader-blue.gif";

// Colors
import Colors from "../../Style/color.scss";

function ExternalFundImportModal(props) {

    const userData = useSelector((store) => store.Reducer.USER_DATA);
    const navigate = useNavigate();
    const otpInputRef = useRef(null);
    let is_edit_allowed = userData.pan_full ? false : true;

    const [pan, setPan] = useState(userData.pan_full);
    const [panError, setPanError] = useState("");
    const [phoneNumber, setPhoneNumber] = useState(userData.phone.country_code + userData.phone.number);
    const [phoneNumberError, setPhoneNumberError] = useState("");

    const [toggleOTP, setToggleOTP] = useState(false);
    const [focusedInput, setFocusedInput] = useState(0);
    const [otpValue, setOtpValue] = useState("");
    const [otpError, setOtpError] = useState("");
    const [timer, setTimer] = useState(30);
    const [loading, setLoading] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [disableResendOTP, setDisableResendOTP] = useState(false); // for Resend OTP
    const [importSuccess, setImportSuccess] = useState(false);
    const [selectedCountryCode, setSelectedCountryCode] = useState("+91"); // Default to 91 (india)

    const [externalImportAPIData, setExternalImportAPIData] = useState("");
    const [externalFundLoader, setExternalFundLoader] = useState(false);

    //for otp timer
    useEffect(() => {

        if (toggleOTP === true && timer > 0) {
            // Start the timer when requestOtpSuccess becomes true
            const countdown = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
            // Clear the timer when the component unmounts or when the timer reaches 0
            return () => {
                clearInterval(countdown);
            };
        }

    }, [timer, toggleOTP]);

    //handle back
    const _handleBack = () => {
        if (isDisabled) return;
        setToggleOTP(false);
        setOtpValue("");
        setLoading(false);
        setIsDisabled(false);
        otpInputRef.current.disabled = false;
    };

    //handle confirm otp
    const _handleChangeOTP = (value) => {
        setOtpValue(value);
        setOtpError("");
    };

    const _handleResendOTP = () => {
        setDisableResendOTP(true);
        _sendOTP("RESEND");
    };

    //handle pan change
    const _handlePanChange = (value) => {

        if (value.length <= 10) {
            setPan(value.toUpperCase());
        };

        setPanError("");
    };

    // handle phone number change
    const _handleChangePhoneNumber = (value, selected_country_code) => {
        setPhoneNumber(value);
        if (selected_country_code) {
            setSelectedCountryCode(selected_country_code);
        }
        setPhoneNumberError("");
    };

    // handle validate inputs
    const _handleSendOtp = () => {

        let valid = true;
        let validate_pan = /^[A-Z]{3}P[A-Z]{1}\d{4}[A-Z]{1}$/; // validation of PAN

        if (!validate_pan.test(pan)) {
            setPanError("Invalid PAN number");
            valid = false;
        }

        if (phoneNumber && phoneNumber.length < 10) {
            setPhoneNumberError("Please enter a valid 10-digit phone number");
            valid = false;
        };

        if (valid) {
            setLoading(true);
            _sendOTP();
        }

    };

    // handle verify otp
    const _handleValidateOTP = () => {

        if (otpValue.length === 0) {
            setOtpError("Please enter the OTP");
            return;
        } else if (otpValue.length !== 6) {
            setOtpError("Please enter the full OTP");
            return;
        };

        // btn loader
        setLoading(true);
        // API Call for invest in selected fund
        _verifyOTP();

    };

    // handle Navigate
    const _handleRefreshPortfolio = () => {
        props.onImportSuccess();
    };


    // ======= API CALLS ======= //
    // ========================= //

    // API to send OTP
    const _sendOTP = (type) => {

        let is_post = true;
        let url = 'mf-services/cas/initiate';
        let data = {
            "pan": pan
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {

                setExternalImportAPIData(response.data);

                if (type == "RESEND") {
                    toast.dismiss();
                    toast.success("OTP sent successfully to mobile number", {
                        className: "e-toast",
                    });
                }

                setToggleOTP(true);
                setFocusedInput(2);
                setTimer(30);

            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            };

            setLoading(false);
            setDisableResendOTP(false);

        });

    };

    // API to verify OTP
    const _verifyOTP = () => {
        setLoading(true);
        let is_post = true;
        let url = 'mf-services/cas/consent';
        let data = {
            "otp": otpValue,
            ...externalImportAPIData
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setExternalFundLoader(true);
                setLoading(false);
                setTimeout(() => {
                    _fetchPortfolio();
                }, 15000);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
                setLoading(false);
            };

        });
    };

    // Get fetch portfolio data
    const _fetchPortfolio = () => {

        let is_post = true;

        let url = 'mf-services/cas/get-statement';
        let data = {
            "req_id": externalImportAPIData.req_id,
            "client_ref_no": externalImportAPIData.client_ref_no
        };

        APIService(is_post, url, data).then((response) => {
            if (response.status_code === 200) {
                setImportSuccess(true);
                setExternalFundLoader(false);
            } else {
                toast.dismiss();
                toast.error(response.message, {
                    className: "e-toast",
                });
            };

            setLoading(false);

        });
    };

    // =============================================== //
    // ============ Inline component ================= //

    const StatusLoader = () => {
        return (
            <div className={`w-100 h-100 position-fixed top-0 start-0 d-flex justify-content-center align-items-center z-3`}>
                {/* overlay */}
                <div className="overlay-background opacity-75"></div>
                <div className="bg-white e-border-radius-8 p-3 d-flex flex-column justify-content-center align-items-center z-index-99">
                    <img src={Loader}
                        height={28}
                        draggable="false"
                        alt='loader' />
                    <p className="e-font-14-res mb-0 mt-2 e-text-egyptian-blue  e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                        Fetching your investments from MFCentral
                    </p>
                </div>
            </div>
        )
    };

    return (
        <div className="modal fade e-manage-modal"
            id="import-portfolio-modal"
            data-bs-backdrop="static"
            tabIndex="-1"
            aria-labelledby="EditPhoneModal"
            aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable min-h90vh">
                {
                    importSuccess === false ?
                        <>
                            {
                                toggleOTP === false ?
                                    (
                                        <div className="modal-content e-bg-lotion e-border-radius-24">
                                            <div className="modal-header border-0 justify-content-space-between py-3 px-3 px-sm-4 ">
                                                <h6 className='e-font-16-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                                    Import external portfolio
                                                </h6>
                                                <img src={CloseBtn}
                                                    id='close-import-modal'
                                                    draggable={false}
                                                    className='cursor-pointer'
                                                    data-bs-dismiss="modal"
                                                    aria-label="Close"
                                                    alt='close'
                                                    onClick={props.onClose} />
                                            </div>

                                            {/* Banner */}
                                            <div className="d-flex align-items-center gap-1 e-bg-anti-flash-light-white p-sm-3 p-2">
                                                <img src={ImportPortfolio}
                                                    alt="import portfolio"
                                                    draggable="false" />
                                                <div className="">
                                                    <h3 className="e-font-14-res mb-1 e-text-charleston-green e-font-16 e-alt-font-poppins e-font-weight-600 line-height-24px">
                                                        Please enter your PAN number
                                                    </h3>
                                                    <p className="mb-0 e-text-onyx e-font-14 e-alt-font-poppins e-font-weight-400 line-height-24px">
                                                        MFCentral will send a 6 digit OTP to your PAN-Linked Mobile number.
                                                    </p>
                                                </div>
                                            </div>

                                            {/* Body */}
                                            <div className="modal-body pb-4 px-3 px-sm-4 pt-3 d-flex flex-column">
                                                <div className="e-bg-cultured e-border-radius-16 p-2 p-sm-3">
                                                    {/* PAN Number */}
                                                    <div className='h-98px'>
                                                        <CustomTextInput label="Enter your PAN*"
                                                            placeholder="ANJPL3753F"
                                                            maxLength={10}
                                                            type="text"
                                                            value={pan}
                                                            errorMsg={panError}
                                                            inputWrapperClassName={`${is_edit_allowed ? "" : "pe-none cursor-default e-border-1px-charleston-green"} `}
                                                            className="w-100 px-3 padding-12px-top padding-12px-bottom e-font-14 line-height-24px"
                                                            handleChange={_handlePanChange} />
                                                    </div>
                                                    {/* Phone Input */}
                                                    <div className="mb-3">
                                                        <CustomPhoneInput label="Phone number"
                                                            placeholder="9876543210"
                                                            className={"e-number-input-padding w-100"}
                                                            type="text"
                                                            value={phoneNumber}
                                                            selectedCountryCode={selectedCountryCode}
                                                            require
                                                            disableCountrySelect
                                                            disabled={true}
                                                            error={phoneNumberError}
                                                            handleChange={_handleChangePhoneNumber}
                                                        />
                                                    </div>
                                                </div>

                                                {/* Info */}
                                                <div className='e-border-radius-16 e-bg-cornsilk p-3 mt-3 '>
                                                    <div className='d-flex align-items-start gap-0-8'>
                                                        <Icon icon="info"
                                                            size="1.3rem"
                                                            color={Colors.ochre} />
                                                        <p className='mb-0 e-text-ochre e-font-14 e-alt-font-poppins e-font-weight-400 line-height-22px letter-spacing-3' >
                                                            Please make sure the phone has linked with the PAN
                                                        </p>
                                                    </div>
                                                </div>


                                                {/* Action buttons */}
                                                <div className='d-flex gap-3 padding-32px-top mt-auto'>
                                                    <SecondaryButton name="Cancel"
                                                        dismiss="modal"
                                                        className="padding-10px-top padding-10px-bottom  w-100 e-border-bright-gray"
                                                        onPress={props.onClose} />
                                                    <PrimaryButton name="Verify"
                                                        loading={loading}
                                                        disabled={loading}
                                                        className="w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                                        onPress={_handleSendOtp} />
                                                </div>


                                            </div>
                                        </div>
                                    ) : (
                                        <div className="modal-content e-bg-lotion e-border-radius-24 e-addbank-box-shadow">
                                            <div className="modal-header justify-content-start e-gap-0-16 pt-4 pb-3 px-3 px-sm-4 border-0">

                                                {/* Hidden close btn for closing modal */}
                                                <span id='mf-invest-modal-close'
                                                    data-bs-dismiss="modal"
                                                    className='d-none'
                                                    aria-label="close"
                                                    alt='close btn' />

                                                <img src={Back}
                                                    draggable={false}
                                                    className='cursor-pointer'
                                                    alt="arrow-back"
                                                    onClick={_handleBack} />
                                                <h6 className='e-font-14-res mb-0 e-text-charleston-green e-font-18 e-alt-font-poppins e-font-weight-600 line-height-32px'>
                                                    Enter OTP
                                                </h6>

                                            </div>
                                            <div className="modal-body pb-4 px-3 px-sm-4 pt-0">

                                                {/* otp section */}
                                                <div className='p-3 e-border-radius-16 e-bg-cultured'>
                                                    {
                                                        userData.phone.country_code == "+91" ?
                                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                                An OTP has been sent to your registered mobile number.
                                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                                    {userData.phone.country_code} {userData.phone.number}
                                                                </span>
                                                            </p>
                                                            :
                                                            <p className='mb-0 pt-3 e-text-onyx e-font-16 e-alt-font-poppins e-font-weight-400 line-height-28px'>
                                                                An OTP has been sent to your registered email.
                                                                <span className='ms-1 e-text-egyptian-blue e-font-weight-500'>
                                                                    {userData.email}
                                                                </span>
                                                            </p>
                                                    }
                                                    <div className='pt-3'>
                                                        <label className="e-font-14-res e-text-charleston-green e-font-14 e-alt-font-poppins e-font-weight-500 line-height-24px">
                                                            Enter your OTP*
                                                        </label>
                                                        <SixDigitOTPInput ref={otpInputRef}
                                                            id="otp"
                                                            value={otpValue}
                                                            error={otpError}
                                                            spellCheck="false"
                                                            focused_input={focusedInput}
                                                            onFocus={() => setFocusedInput(2)}
                                                            onBlur={() => setFocusedInput(0)}
                                                            onChange={_handleChangeOTP} />

                                                        <div className='e-inline-flex'>
                                                            <button className={`${timer === 0 ? "text-decoration-hover-underline" : "e-disable"} border-0 e-bg-transparent e-text-egyptian-blue e-font-12 e-alt-font-poppins e-font-weight-500 line-height-26px ps-0`}
                                                                disabled={disableResendOTP}
                                                                onClick={_handleResendOTP} >
                                                                Re-send OTP
                                                            </button>
                                                            {timer !== 0 &&
                                                                <p className='mb-0 e-text-charleston-green e-font-12 e-alt-font-poppins e-font-weight-600 line-height-26px'>
                                                                    in {timer} {timer == 1 ? "second" : "seconds"}
                                                                </p>
                                                            }
                                                        </div>
                                                    </div>

                                                    <p className='mb-0 pt-3 e-text-sonic-silver e-font-12 e-alt-font-poppins e-font-weight-400 line-height-18px'>
                                                        *In accordance with regulatory, 2FA verification is required in order to create an order.
                                                    </p>
                                                </div>

                                                <div className='d-flex gap-3 padding-32px-top mt-auto mb-2'>
                                                    <SecondaryButton name="Cancel"
                                                        dismiss="modal"
                                                        className="padding-10px-top padding-10px-bottom  w-100 e-border-bright-gray"
                                                        onPress={props.onClose} />
                                                    <PrimaryButton name="Confirm"
                                                        loading={loading}
                                                        disabled={loading}
                                                        className="w-100 padding-12px-top padding-12px-bottom e-primary-button-hover transition"
                                                        onPress={_handleValidateOTP} />
                                                </div>
                                            </div>
                                        </div>
                                    )
                            }
                        </>
                        :
                        <div className=" modal-content e-bg-lotion e-border-radius-24 e-modal-box-shadow ">
                            <div className="modal-gradient-bg d-flex flex-column align-items-center modal-body p-4 e-border-radius-24">
                                <img src={CloseBtnWhite}
                                    width={32}
                                    draggable={false}
                                    className='cursor-pointer float-end align-self-end'
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                    alt='close'
                                    onClick={props.onClose} />
                                <img className="img-fluid col-5 mt-4 pt-3"
                                    draggable={false}
                                    src={ImportPortfolioSuccess}
                                    alt="payment status image" />
                                <p className="e-font-14-res text-center e-text-ufo-green e-alt-font-poppins e-font-18 e-font-weight-600 mt-4 mb-2">
                                    Completed
                                </p>
                                <p className="e-font-12-res line-height-20px-res col-sm-10 mx-auto text-center e-text-dark-liver e-alt-font-poppins e-font-14 e-font-weight-400 line-height-26px text-center mx-auto mb-0 mb-5 ">
                                    External Folio import completed successfully. It will reflect in your dashboard in 5-8 mins.
                                </p>
                                <PrimaryButton name="View Portfolio"
                                    dismiss="modal"
                                    className="padding-12px-tb w-100"
                                    onPress={_handleRefreshPortfolio} />
                            </div>
                        </div>
                }
            </div>
            {
                externalFundLoader === true &&
                <StatusLoader />
            }
        </div>
    )
}

export default memo(ExternalFundImportModal);


